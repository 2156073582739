<template>
  <el-container>
    <el-header>
      <golobalHead></golobalHead>
    </el-header>
    <el-container>
      <el-aside width="200px">
        <el-menu
          router
          default-active="/student/hometown"
          class="el-menu-vertical-demo"
          @open="handleOpen"
          @close="handleClose"
        >
          <el-submenu index="" disabled>
            <template slot="title">
              <i class="el-icon-location"></i>
              <span>暂未开放</span>
            </template>
            <el-menu-item-group>
              <template slot="title">分组一</template>
              <el-menu-item index="1-1">选项1</el-menu-item>
              <el-menu-item index="1-2">选项2</el-menu-item>
            </el-menu-item-group>
            <el-menu-item-group title="分组2">
              <el-menu-item index="1-3">选项3</el-menu-item>
            </el-menu-item-group>
            <el-submenu index="1-4">
              <template slot="title">选项4</template>
              <el-menu-item index="1-4-1">选项1</el-menu-item>
            </el-submenu>
          </el-submenu>
          <el-menu-item index="" disabled>
            <i class="el-icon-menu"></i>
            <span slot="title">暂未开放</span>
          </el-menu-item>
          <el-menu-item index="/student/hometown">
            <i class="el-icon-document"></i>
            <span slot="title">学生信息</span>
          </el-menu-item>
          <el-menu-item index="/student/sclass">
            <i class="el-icon-setting"></i>
            <span slot="title">校园设置</span>
          </el-menu-item>
        </el-menu>
      </el-aside>
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      activeIndex2: '1',
      isCollapse: false,
    }
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath)
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath)
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath)
    },
  },
}
</script>

<style lang="less" scoped>
.el-header,
.el-footer {
  background-color: #b3c0d1;
  color: #333;
  text-align: center;
  line-height: 60px;
}
.el-header {
  padding: 0;
}
.el-aside {
  background-color: #d3dce6;
  color: #333;
  text-align: center;
  line-height: 200px;
  .el-menu {
    height: 100%;
  }
}

.el-main {
  background-color: #e9eef3;
  color: #333;
  text-align: left;
}
</style>
